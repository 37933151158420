export enum Player {
  BITMOVIN = 'bitmovin',
  JW = 'jw',
  RADIANT = 'radiant',
  VIDEOJS = 'videojs',
  HLSJS = 'hlsjs',
  SHAKA = 'shaka',
  DASHJS = 'dashjs',
  HTML5 = 'html5',
}
