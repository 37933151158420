import { AdapterAPI } from '../api/AdapterAPI';
import { AnalyticsStateMachineOptions } from '../types/AnalyticsStateMachineOptions';
import { AnalyticsConfig } from '../types/AnalyticsConfig';
import { Analytics } from '../core/Analytics';
import { DashjsInternalAdapter } from './internal/DashjsInternalAdapter';
import { Adapter } from './Adapter';

export class DashjsAdapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();
    this.internalAdapter = new DashjsInternalAdapter(player, opts);
    this.analytics = new Analytics(config, player, this.internalAdapter);
  }
}
