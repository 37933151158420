export enum Event {
  READY = 'ready',
  SOURCE_LOADED = 'sourceLoaded',
  PLAY = 'play',
  PLAYING = 'playing',
  PAUSE = 'pause',
  TIMECHANGED = 'timechanged',
  SEEK = 'seek',
  SEEKED = 'seeked',
  START_CAST = 'startCasting',
  END_CAST = 'endCasting',
  START_BUFFERING = 'startBuffering',
  END_BUFFERING = 'endBuffering',
  AUDIOTRACK_CHANGED = 'audioTrackChanged',
  AUDIO_CHANGE = 'audioChange',
  VIDEO_CHANGE = 'videoChange',
  START_FULLSCREEN = 'startFullscreen',
  END_FULLSCREEN = 'endFullscreen',
  START_AD = 'adStart',
  SUBTITLE_CHANGE = 'subtitleChange',
  END_AD = 'adEnd',
  MUTE = 'mute',
  UN_MUTE = 'unMute',
  ERROR = 'playerError',
  PLAYBACK_FINISHED = 'end',
  SCREEN_RESIZE = 'resize',
  UNLOAD = 'unload',
  END = 'end',
  METADATA_LOADED = 'metadataLoaded',
  SOURCE_UNLOADED = 'sourceUnloaded',
  MANUAL_SOURCE_CHANGE = 'manualSourceChangeInitiated',
}
