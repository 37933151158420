// tslint:disable:no-shadowed-variable
import { InternalAdapter } from './InternalAdapter';
import { InternalAdapterAPI } from './InternalAdapterAPI';
import { DrmPerformanceInfo } from '../../types/DrmPerformanceInfo';
import { AnalyticsStateMachineOptions } from '../../types/AnalyticsStateMachineOptions';
import { VideojsAnalyticsStateMachine } from '../../analyticsStateMachines/VideoJsAnalyticsStateMachine';
import { Player } from '../../enums/Player';
import { StreamSources } from '../../types/StreamSources';
import { PlaybackInfo } from '../../types/PlaybackInfo';
import { Event } from '../../enums/Event';
import { getMIMETypeFromFileExtension } from '../../enums/MIMETypes';
import { getStreamTypeFromMIMEType } from '../../enums/StreamTypes';
import { PlayerSize } from '../../enums/PlayerSize';
import { QualityLevelInfo } from '../../types/QualityLevelInfo';

export class VideojsInternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  private _drmPerformanceInfo: DrmPerformanceInfo = {drmUsed: false};
  private onBeforeUnLoadEvent: boolean = false;

  constructor(private player: videojs.default.Player, opts?: AnalyticsStateMachineOptions) {
    super(opts);
    this.stateMachine = new VideojsAnalyticsStateMachine(this.stateMachineCallbacks, this.opts);
    this.register();
  }

  get drmPerformanceInfo(): DrmPerformanceInfo {
    return this._drmPerformanceInfo;
  }
  public getPlayerVersion = () => ((window as any).videojs ? (window as any).videojs.VERSION : 'unknown');
  public getPlayerName = () => Player.VIDEOJS;
  public getPlayerTech = () => 'html5';
  public getAutoPlay = () => this.player.autoplay() as any == true; // tslint:disable-line:triple-equals

  public getStreamType(url: string) {
    const mimeType = getMIMETypeFromFileExtension(url);
    if (mimeType) {
      return getStreamTypeFromMIMEType(mimeType);
    }
  }

  // this seems very generic. one could put it in a helper
  // and use it in many adapter implementations.
  public getStreamSources(url: string): StreamSources {
    const streamType = this.getStreamType(url);
    switch (streamType) {
      case 'hls':
        return {m3u8Url: url};
      case 'dash':
        return {mpdUrl: url};
      default:
        return {progUrl: url};
    }
  }

  public getCurrentPlaybackInfo(): PlaybackInfo {
    const info: PlaybackInfo = {
      ...this.getCommonPlaybackInfo(),
      ...this.getStreamSources(this.player.currentSrc()),
      streamFormat: this.getStreamType(this.player.currentSrc()),
      isLive: this.player.duration() === Infinity,
      size: (this.player as any).isFullscreen() ? PlayerSize.Fullscreen : PlayerSize.Window,
      playerTech: this.getPlayerTech(),
      isMuted: this.player.muted(),
      videoDuration: this.player.duration(),
      videoWindowHeight: this.player.height(),
      videoWindowWidth: this.player.width(),
      videoPlaybackHeight: (this.player as any).videoHeight(),
      videoPlaybackWidth: (this.player as any).videoWidth(),
      droppedFrames: 0, // TODO
      // TODO audioBitrate:
      // TODO isCasting:
      // TODO videoTitle: (currently only from the analytics config)
    };

    const qualityInfo = this.getCurrentQualityLevelInfo();
    if (qualityInfo) {
      info.videoPlaybackWidth = qualityInfo.width || info.videoPlaybackWidth;
      info.videoPlaybackWidth = qualityInfo.height || info.videoPlaybackHeight;
      info.videoBitrate = qualityInfo.bitrate;
    }

    return info;
  }

  public getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    // TODO: Needs to be implemented
    return null;
  }

  public register() {
    const that = this;
    let analyticsBitrate: any;
    this.player.on('loadedmetadata', function(this: any) {
      that.eventCallback(Event.SOURCE_LOADED, {});
    });
    this.player.ready(function(this: any) {
      that.eventCallback(Event.READY, {});
    });
    this.player.on(Event.PLAY, function(this: any) {
      that.eventCallback(Event.PLAY, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on(Event.PAUSE, function(this: any) {
      that.eventCallback(Event.PAUSE, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('error', function(this: any) {
      const error = this.error();
      that.eventCallback(Event.ERROR, {
        currentTime: this.currentTime(),
        code: error.code,
        message: error.message,
      });
    });
    this.player.on('volumechange', function(this: any) {
      const muted = this.muted();
      if (muted) {
        that.eventCallback(Event.MUTE, {
          currentTime: this.currentTime(),
        });
      } else {
        that.eventCallback(Event.UN_MUTE, {
          currentTime: this.currentTime(),
        });
      }
    });
    this.player.on(Event.SEEK, function(this: any) {
      that.eventCallback(Event.SEEK, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on(Event.SEEKED, function(this: any) {
      that.eventCallback(Event.SEEKED, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('stalled', function(this: any) {
      that.eventCallback(Event.START_BUFFERING, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('waiting', function(this: any) {
      that.eventCallback(Event.START_BUFFERING, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('timeupdate', function(this: any) {
      that.eventCallback(Event.TIMECHANGED, {
        currentTime: this.currentTime(),
      });

      // that is not the quality that is currently being played.
      // for more accuracy one can use the segment-metadata cue tracking:
      // https://github.com/videojs/videojs-contrib-hls#segment-metadata
      const selectedPlaylist = this.tech_.hls.playlists.media();
      if (!selectedPlaylist) {
        return;
      }

      const {attributes} = selectedPlaylist;
      const bitrate = attributes.BANDWIDTH;
      const width = (attributes.RESOLUTION || {}).width;
      const height = (attributes.RESOLUTION || {}).height;

      if (analyticsBitrate !== bitrate) {
        const eventObject = {
          width,
          height,
          bitrate,
          currentTime: this.currentTime(),
        };

        that.eventCallback(Event.VIDEO_CHANGE, eventObject);
        analyticsBitrate = bitrate;
      }

      // Check for HLS source-handler (videojs-contrib-hls)
      // When we just use Videojs without any specific source-handler (not using MSE API based engine)
      // but just native technology (HTML5/Flash) to do for example "progressive download" with plain Webm/Mp4
      // or use native HLS on Safari this may not not be present. In that case Videojs is just
      // a wrapper around the respective playback tech (HTML or Flash).

      const tech = this.tech({IWillNotUseThisInPlugins: true});
      if (tech.hls) {
        // From here we are going onto Videojs-HLS source-handler specific API
        //
        const hls = this.tech_.hls;

        // Maybe we have the HLS source-handler initialized, but it is
        // not actually activated and used (just wrapping HTML5 built-in HLS playback like in Safari)
        if (!hls.playlists || typeof hls.playlists.media !== 'function') {
          return;
        }

        // Check for current media playlist
        const selectedPlaylist = hls.playlists.media();
        if (!selectedPlaylist) {
          return;
        }

        const {attributes} = selectedPlaylist;
        const bitrate = attributes.BANDWIDTH;
        const width = (attributes.RESOLUTION || {}).width;
        const height = (attributes.RESOLUTION || {}).height;

        // update actual bitrate
        if (isNaN(analyticsBitrate) || analyticsBitrate !== bitrate) {
          const eventObject = {
            width,
            height,
            bitrate,
            currentTime: this.currentTime(),
          };

          that.eventCallback(Event.VIDEO_CHANGE, eventObject);
          analyticsBitrate = bitrate;
        }
      }
    });

    window.onunload = window.onbeforeunload = () => {
      if (!this.onBeforeUnLoadEvent) {
        this.onBeforeUnLoadEvent = true;
        this.eventCallback(Event.UNLOAD, {
          currentTime: this.player.currentTime(),
        });
      }
    };
  }

  public sourceChange(config: any, timestamp: number) {
    this.stateMachine.sourceChange(config, timestamp, this.player.currentTime());
  }
}
