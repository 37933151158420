import { Analytics } from './Analytics';
import { logger } from '../utils/Logger';
import { AdSample } from '../types/AdSample';
import * as Utils from '../utils/Utils';
import { ViewportTracker } from '../utils/ViewportTracker';
import {
  AdBreakEvent,
  AdClickedEvent,
  AdEvent,
  AdQuartileEvent,
  AdLinearityChangedEvent,
  ErrorEvent,
  AdManifestLoadedEvent,
  AdTagConfig,
  AdBreak,
  Ad,
} from 'bitmovin-player';
import { Sample } from '../types/Sample';
import { AdAnalyticsSample } from '../types/AdAnalyticsSample';
import { AdBreakSample } from '../types/AdBreakSample';
import { AdModuleAPI } from '../adapters/internal/ads/AdModuleAPI';

enum AdQuartile {
  FIRST_QUARTILE = 'firstQuartile',
  MIDPOINT = 'midpoint',
  THIRD_QUARTILE = 'thirdQuartile',
}

declare var __VERSION__: any;

export class AdAnalytics {
  public static readonly MODULE_NAME = 'ads';
  private static readonly TIMEOUT_CURRENT_TIME_INTERVAL = 100;

  private onBeforeUnLoadEvent: boolean = false;
  private analytics: Analytics;
  private adapter: AdModuleAPI;
  private viewportTracker: ViewportTracker;

  private activeAdBreakSample?: AdBreakSample;
  private activeAdSample?: AdSample;

  private adManifestLoadedEvents: AdManifestLoadedEvent[] = [];
  private adStartupTimestamp?: number;
  private beginPlayingTimestamp?: number;
  private enterViewportTimestamp?: number;
  private isPlaying: boolean = false;
  private currentTime?: number;
  private currentTimeInterval?: ReturnType<typeof window.setTimeout>;
  private adPodPosition: number = 0;

  constructor(analytics: Analytics, adapter: AdModuleAPI) {
    this.analytics = analytics;
    this.adapter = adapter;

    this.adapter.adCallbacks.onAdStarted = (event) => this.onAdStarted(event);
    this.adapter.adCallbacks.onAdFinished = (event) => this.onAdFinished(event);
    this.adapter.adCallbacks.onAdBreakStarted = (event) => this.onAdBreakStarted(event);
    this.adapter.adCallbacks.onAdBreakFinished = (event) => this.onAdBreakFinished(event);
    this.adapter.adCallbacks.onAdClicked = (event) => this.onAdClicked(event);
    this.adapter.adCallbacks.onAdError = (event) => this.onAdError(event);
    this.adapter.adCallbacks.onAdManifestLoaded = (event) => this.onAdManifestLoaded(event);
    this.adapter.adCallbacks.onPlay = () => this.onPlay();
    this.adapter.adCallbacks.onPause = () => this.onPause();
    this.adapter.adCallbacks.onBeforeUnload = () => this.onBeforeUnload();
    this.adapter.adCallbacks.onAdSkipped = (event) => this.onAdSkipped(event);
    this.adapter.adCallbacks.onAdQuartile = (event) => this.onAdQuartile(event);

    this.viewportTracker = new ViewportTracker(this.adapter.getContainer(), () => this.onIntersectionChanged(), 0.5);
  }

  public onIntersectionChanged() {
    if (!this.activeAdSample) {
      return;
    }
    if (this.isContainerInViewport()) {
      this.enterViewportTimestamp = Utils.getCurrentTimestamp();
    } else {
      if (this.enterViewportTimestamp) {
        this.activeAdSample.timeInViewport =
          (this.activeAdSample.timeInViewport || 0) + Utils.getCurrentTimestamp() - this.enterViewportTimestamp;
      }
    }
  }

  public isContainerInViewport(): boolean {
    return this.viewportTracker ? this.viewportTracker.isInViewport() : true;
  }

  public onPlay() {
    if (this.adapter && this.adapter.isLinearAdActive() && this.activeAdSample) {
      const timestamp = Utils.getCurrentTimestamp();
      this.beginPlayingTimestamp = timestamp;
      this.enterViewportTimestamp = timestamp;
      this.isPlaying = true;
    }
  }

  public onPause() {
    if (this.adapter && this.adapter.isLinearAdActive() && this.activeAdSample) {
      this.updatePlayingTime(this.activeAdSample);
      this.isPlaying = false;
    }
  }

  public onAdManifestLoaded(event: AdManifestLoadedEvent) {
    const adTagConfig = event.adConfig as AdTagConfig;
    if (adTagConfig && adTagConfig.tag && adTagConfig.tag.type === 'vmap') {
      this.sendAnalyticsRequest(new AdBreakSample(event.adConfig, event));
    } else if (event.adBreak) {
      this.adManifestLoadedEvents.push(event);
    }
  }

  public onAdBreakStarted(event: AdBreakEvent) {
    this.adPodPosition = 0;
    this.activeAdBreakSample = new AdBreakSample(event.adBreak, this.getAdManifestLoadedEvent(event.adBreak));
    this.adStartupTimestamp = Utils.getCurrentTimestamp();
  }

  public onAdBreakFinished(event: AdBreakEvent) {
    this.resetActiveAd();
    this.activeAdBreakSample = undefined;
  }

  public onAdStarted(event: AdEvent) {
    if (!event.ad.isLinear) {
      return;
    }

    this.resetActiveAd();
    this.activeAdSample = new AdSample(event.ad);
    this.currentTime = undefined;
    this.activeAdSample.adStartupTime = this.adStartupTimestamp
      ? Utils.getCurrentTimestamp() - this.adStartupTimestamp
      : undefined;

    this.startAd(this.activeAdSample);
  }

  public onAdFinished(event: AdEvent) {
    if (!this.activeAdBreakSample || !this.activeAdSample) {
      return;
    }

    const adSample = {...this.activeAdSample};
    adSample.completed = 1;
    this.resetActiveAd();
    this.completeAd(this.activeAdBreakSample, adSample, adSample.adDuration);
  }

  public onAdSkipped(event: AdEvent) {
    if (!this.activeAdBreakSample || !this.activeAdSample) {
      return;
    }

    const adSample = {...this.activeAdSample};
    adSample.skipped = 1;
    adSample.skipPosition = this.currentTime;
    adSample.skipPercentage =
      Utils.calculatePercentage(this.activeAdSample.skipPosition, this.activeAdSample.adDuration);
    this.resetActiveAd();
    this.completeAd(this.activeAdBreakSample, adSample, adSample.skipPosition);
  }

  public onAdError(event: ErrorEvent) {
    const {adConfig, adBreak, code, message} = event.data ||
      { adBreak: undefined,
        adConfig: undefined,
        code: undefined,
        message: undefined };

    const adBreakSample =
      new AdBreakSample(adBreak || adConfig, adBreak ? this.getAdManifestLoadedEvent(adBreak) : undefined);

    adBreakSample.errorCode = code || event.code;
    adBreakSample.errorData = JSON.stringify(event.data);
    adBreakSample.errorMessage = message || event.name;

    let adSample;
    let errorPosition;
    if (this.activeAdSample &&
      adBreak &&
      adBreak.ads &&
      (adBreak.ads as any[]).includes((ad: Ad) => ad.id === (this.activeAdSample as AdSample).adId)) {
      adSample = this.activeAdSample;
      errorPosition = adSample.errorPosition = this.currentTime;
      adSample.errorPercentage =
        Utils.calculatePercentage(adSample.errorPosition, adSample.adDuration);
    }
    this.completeAd(adBreakSample, adSample, errorPosition);
  }

  public onAdLinearityChanged(event: AdLinearityChangedEvent) {}

  public onAdClicked(event: AdClickedEvent) {
    if (!this.activeAdSample) {
      return;
    }
    this.activeAdSample.adClickthroughUrl = event.clickThroughUrl;
    this.activeAdSample.clicked = 1;
    this.activeAdSample.clickPosition = this.currentTime;
    this.activeAdSample.clickPercentage =
      Utils.calculatePercentage(this.activeAdSample.clickPosition, this.activeAdSample.adDuration);
  }

  public onAdQuartile(event: AdQuartileEvent) {
    if (!this.activeAdSample) {
      return;
    }
    if (event.quartile === AdQuartile.FIRST_QUARTILE) {
      this.activeAdSample.quartile1 = 1;
    } else if (event.quartile === AdQuartile.MIDPOINT) {
      this.activeAdSample.midpoint = 1;
    } else if (event.quartile === AdQuartile.THIRD_QUARTILE) {
      this.activeAdSample.quartile3 = 1;
    }
  }

  public onBeforeUnload() {
    if (this.onBeforeUnLoadEvent) {
      return;
    }
    this.onBeforeUnLoadEvent = true;
    if (!this.activeAdSample || !this.activeAdBreakSample) {
      return;
    }

    const adSample = {...this.activeAdSample};
    adSample.closed = 1;
    adSample.closePosition = this.currentTime;
    adSample.closePercentage =
      Utils.calculatePercentage(adSample.closePosition, adSample.adDuration);
    this.resetActiveAd();
    this.completeAd(this.activeAdBreakSample, adSample, adSample.closePosition);
  }

  public createNewAdAnalyticsSample(analyticsSample: Sample): AdAnalyticsSample {
    const moduleInfo = this.adapter.getAdModuleInfo();
    return {
      ...new AdAnalyticsSample(analyticsSample),
      analyticsVersion: __VERSION__,
      adModule: moduleInfo.name,
      adModuleVersion: moduleInfo.version,
      playerStartupTime: this.analytics.playerStartupTime,
      pageLoadTime: this.analytics.pageLoadTime,
      autoplay: this.analytics.autoplay,
      pageLoadType: Utils.getPageLoadType(),
    };
  }

  private getAdManifestLoadedEvent(adBreak: AdBreak) {
    if(!adBreak) {
      return undefined;
    }
    return this.adManifestLoadedEvents.find((i) => i.adBreak && i.adBreak.id === adBreak.id);
  }

  private sendAnalyticsRequest(adBreakSample: AdBreakSample, adSample?: AdSample) {
    const sample = {
      ...this.createNewAdAnalyticsSample(this.analytics.sample),
      ...adBreakSample,
      ...adSample || new AdSample(),
    };
    sample.time = Utils.getCurrentTimestamp();
    sample.adImpressionId = Utils.generateUUID();
    sample.percentageInViewport = Utils.calculatePercentage(sample.timeInViewport, sample.timePlayed);
    this.analytics.backend.sendAdRequest(sample);
  }

  private updatePlayingTime(adSample: AdSample) {
    const timestamp = Utils.getCurrentTimestamp();
    if (this.beginPlayingTimestamp && this.isPlaying) {
      if (adSample.timePlayed !== undefined) {
        adSample.timePlayed += timestamp - this.beginPlayingTimestamp;
      }
      if (this.isContainerInViewport() &&
          this.enterViewportTimestamp &&
          adSample.timeInViewport !== undefined) {
        adSample.timeInViewport += timestamp - this.enterViewportTimestamp;
      }
    }
  }

  private startAd(adSample: AdSample) {
    adSample.started = 1;
    adSample.timePlayed = 0;
    adSample.timeInViewport = 0;
    adSample.adPodPosition = this.adPodPosition;
    const timestamp = Utils.getCurrentTimestamp();
    this.beginPlayingTimestamp = timestamp;
    this.enterViewportTimestamp = this.isContainerInViewport() ? timestamp : undefined;
    this.isPlaying = true;
    this.currentTime = 0;
    this.adPodPosition++;
    this.currentTimeInterval = window.setInterval(() => {
      try {
        if (adSample &&
            adSample.adDuration !== undefined &&
            adSample.adDuration > 0 &&
            this.adapter.isLinearAdActive()) {
          this.currentTime = Utils.calculateTime(Math.max(this.adapter.currentTime(), 0));
        }
      } catch (e) {
        logger.log('AdStarted monitoring interval failed and got cleared', e);
        this.resetActiveAd();
      }
    }, AdAnalytics.TIMEOUT_CURRENT_TIME_INTERVAL);
  }

  private completeAd(adBreakSample: AdBreakSample, adSample: AdSample = new AdSample(), exitPosition?: number) {
    adSample.exitPosition = exitPosition;
    adSample.playPercentage =
      Utils.calculatePercentage(adSample.exitPosition, adSample.adDuration);

    // reset startupTimestamp for the next ad, in case there are multiple ads in one ad break
    this.adStartupTimestamp = Utils.getCurrentTimestamp();
    this.updatePlayingTime(adSample);
    this.isPlaying = false;
    this.sendAnalyticsRequest(adBreakSample, adSample);
  }

  private resetActiveAd() {
    window.clearInterval(this.currentTimeInterval);
    this.currentTime = undefined;
    this.activeAdSample = undefined;
  }
}
