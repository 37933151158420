import { InternalAdapterAPI } from './InternalAdapterAPI';
import { HTML5InternalAdapter } from './HTML5InternalAdapter';
import { AnalyticsStateMachineOptions } from '../../types/AnalyticsStateMachineOptions';
import { QualityLevelInfo } from '../../types/QualityLevelInfo';
import { Player } from '../../enums/Player';
import { MIMETypes } from '../../enums/MIMETypes';

export class DashjsInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  private onBeforeUnLoadEvent: boolean = false;

  constructor(private mediaPlayer: any, opts?: AnalyticsStateMachineOptions) {
    super(null, opts);
  }

  /**
   * @override
   */
  public initialize() {
    let videoEl: HTMLVideoElement | null = null;
    let canPlay = false;
    try {
      videoEl = this.mediaPlayer.getVideoElement();
    } catch (e) {} // tslint:disable-line:no-empty

    if (!videoEl) {
      this.mediaPlayer.on(
        'canPlay',
        () => {
          if (canPlay) {
            return;
          }
          videoEl = this.mediaPlayer.getVideoElement();
          canPlay = true;
          this.setMediaElement(videoEl);
        },
        this,
      );
    } else {
      this.setMediaElement(videoEl);
    }
  }

  public getPlayerName = () => Player.DASHJS;
  public getPlayerVersion = () => this.mediaPlayer.getVersion();

  /**
   * @override
   */
  public getMIMEType() {
    return MIMETypes.DASH;
  }
  /**
   * @override
   */
  public getStreamURL(): string | undefined {
    if (!this.mediaPlayer) {
      return;
    }

    const source = this.mediaPlayer.getSource();
    if (source) {
      return source.toString();
    }
  }
  /**
   * Implemented by sub-class to deliver current quality-level info
   * specific to media-engine.
   * @override
   * @returns {QualityLevelInfo}
   */
  public getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    if (this.mediaPlayer) {
      const videoBitrateInfoList = this.mediaPlayer.getBitrateInfoListFor('video');
      const currentVideoQualityIndex = this.mediaPlayer.getQualityFor('video');
      const currentVideoQuality = videoBitrateInfoList[currentVideoQualityIndex];
      const {width, height, bitrate} = currentVideoQuality;
      return {
        width,
        height,
        bitrate,
      };
    }
    return null;
  }
}
