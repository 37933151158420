import { Backend } from './Backend';
import { AnalyticsCall } from '../utils/AnalyticsCall';
import { Sample } from '../types/Sample';
import { noOp } from '../utils/Utils';
import { AdSample } from '../types/AdSample';
import { AdBreakSample } from '../types/AdBreakSample';
import { AdAnalyticsSample } from '../types/AdAnalyticsSample';

export class RemoteBackend implements Backend {
  public analyticsCall: AnalyticsCall;
  public hasAdModule: boolean;

  constructor(hasAdModule: boolean, backendBaseUrl: string, private licenseKey: string) {
    this.hasAdModule = hasAdModule;
    this.analyticsCall = new AnalyticsCall(backendBaseUrl);
  }

  public sendRequest(sample: Sample) {
    sample.key = this.licenseKey;
    this.analyticsCall.sendRequest(sample, noOp);
  }

  public sendUnloadRequest(sample: Sample) {
    sample.key = this.licenseKey;
    this.analyticsCall.sendUnloadRequest(sample);
  }

  public sendRequestSynchronous(sample: Sample) {
    sample.key = this.licenseKey;
    this.analyticsCall.sendRequestSynchronous(sample, noOp);
  }

  public sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {
    sample.key = this.licenseKey;
    if (!this.hasAdModule) {
      return;
    }
    this.analyticsCall.sendAdRequest(sample);
  }
}
