import { Player } from '../enums/Player';
import { CdnProvider } from '../enums/CDNProvider';
import { VERSION } from '../Version';
import { HlsAdapter } from '../adapters/HlsAdapter';
import { Bitmovin8Adapter } from '../adapters/Bitmovin8Adapter';
import { Bitmovin7Adapter } from '../adapters/Bitmovin7Adapter';
import { VideojsAdapter } from '../adapters/VideoJsAdapter';
import { ShakaAdapter } from '../adapters/ShakaAdapter';
import { DashjsAdapter } from '../adapters/DashjsAdapter';
import { HTMLVideoElementAdapter } from '../adapters/HTMLVideoElementAdapter';

// tslint:disable-next-line:variable-name
const AnalyticsModule = {
  name: 'analytics',
  module: {
    Analytics: Bitmovin8Adapter,
  },
  hooks: {
    setup: (module: any, player: any) => {
      const config = player.getConfig();
      if (!config || config.analytics === false) {
        return Promise.resolve();
      }
      const analyticsModule = module.Analytics;
      const analyticsModuleInstance = new analyticsModule(player);
      return Promise.resolve(analyticsModuleInstance);
    },
  },
};

export { Player as Players };
export { CdnProvider as CdnProviders };
export { AnalyticsModule as PlayerModule };
export { VERSION as version };

export { HlsAdapter };
export { Bitmovin8Adapter };
export { Bitmovin7Adapter };
export { VideojsAdapter };
export { ShakaAdapter };
export { DashjsAdapter };
export { HTMLVideoElementAdapter };

const adapters = {
  HlsAdapter,
  Bitmovin8Adapter,
  Bitmovin7Adapter,
  VideojsAdapter,
  ShakaAdapter,
  DashjsAdapter,
  HTMLVideoElementAdapter,
};
export { adapters };

export default {
  PlayerModule: AnalyticsModule,
};
