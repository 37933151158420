import { InternalAdapterAPI } from './InternalAdapterAPI';
import { HTML5InternalAdapter } from './HTML5InternalAdapter';
import { AnalyticsStateMachineOptions } from '../../types/AnalyticsStateMachineOptions';
import { QualityLevelInfo } from '../../types/QualityLevelInfo';
import { Player } from '../../enums/Player';
import { getMIMETypeFromFileExtension } from '../../enums/MIMETypes';
import { getBrowserInfo } from '../../utils/Utils';

export class HTMLVideoElementInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  constructor(mediaElement: HTMLVideoElement, opts?: AnalyticsStateMachineOptions) {
    super(mediaElement, opts);
  }

  public getPlayerName = () => Player.HTML5;
  public getPlayerVersion = () => {
    const browserInfo = getBrowserInfo();
    return `${browserInfo.name} ${browserInfo.version}`;
  }
  /**
   * @override
   */
  public getStreamURL() {
    return this.mediaElement ? this.mediaElement.currentSrc : undefined;
  }

  /**
   * Implemented by sub-class to deliver current quality-level info
   * specific to media-engine.
   * @override
   * @returns {QualityLevelInfo}
   */
  public getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    return {
      bitrate: undefined,
      width: this.mediaElement ? this.mediaElement.videoWidth : undefined,
      height: this.mediaElement ? this.mediaElement.videoHeight : undefined,
    };
  }
}
