export class EventDebugging {
  public event: string | undefined;
  public from: string | undefined;
  public to: string | undefined;
  public timestamp: number;
  public eventObject: any;

  constructor(
    event: string | undefined,
    from: string | undefined,
    to: string | undefined,
    timestamp: number,
    eventObject: any,
  ) {
    this.event = event;
    this.from = from;
    this.to = to;
    this.timestamp = timestamp;
    this.eventObject = eventObject;
  }
}
