import { AdConfig, AdTagConfig, AdBreakConfig, AdBreak, AdManifestLoadedEvent, AdTag } from 'bitmovin-player';
import * as Utils from '../utils/Utils';

export class AdBreakSample {
  public adPosition?: string;
  public adOffset?: string;
  public adScheduleTime?: number;
  public adReplaceContentDuration?: number;
  public adPreloadOffset?: number;
  public adTagPath?: string;
  public adTagServer?: string;
  public adTagType?: string;
  public adTagUrl?: string;
  public adIsPersistent?: boolean;
  public adIdPlayer?: string;
  public manifestDownloadTime?: number;
  public errorCode?: number;
  public errorData?: string;
  public errorMessage?: string;
  public adFallbackIndex: number = 0;

  constructor(adConfig?: AdConfig, adManifestLoadedEvent?: AdManifestLoadedEvent) {
    if (adManifestLoadedEvent) {
      this.manifestDownloadTime = Utils.calculateTime(adManifestLoadedEvent.downloadTiming.downloadTime);
    }

    if (adConfig) {
      this.adReplaceContentDuration = Utils.calculateTimeWithUndefined(adConfig.replaceContentDuration);
      this.adFallbackIndex = ((adConfig as any).currentFallbackIndex || -1) + 1;
    }

    const adBreakConfig = adConfig as AdBreakConfig;
    if (adBreakConfig) {
      if (adBreakConfig.position === 'pre' || adBreakConfig.position === 'post') {
        this.adPosition = adBreakConfig.position;
      } else {
        this.adPosition = 'mid';
        this.adOffset = adBreakConfig.position;
      }

      this.adPreloadOffset = Utils.calculateTimeWithUndefined(adBreakConfig.preloadOffset);
    }

    const adTagConfig = adConfig as AdTagConfig;
    if (adTagConfig) {
      this.adIsPersistent = adTagConfig.persistent;
      let tag = adTagConfig.tag;
      if(this.adFallbackIndex > 0 &&
        adTagConfig.fallbackTags &&
        adTagConfig.fallbackTags.length >= this.adFallbackIndex) {
        tag = adTagConfig.fallbackTags[this.adFallbackIndex - 1];
      }
      if(tag) {
        this.adTagType = tag.type;
        this.adTagUrl = tag.url;
      }
      if (this.adTagUrl) {
        const adTagDetails = Utils.getHostnameAndPathFromUrl(this.adTagUrl);
        this.adTagServer = adTagDetails.hostname;
        this.adTagPath = adTagDetails.path;
      }
    }

    const adBreak = adConfig as AdBreak;
    if (adBreak) {
      this.adScheduleTime = Utils.calculateTimeWithUndefined(adBreak.scheduleTime);
      this.adIdPlayer = adBreak.id;
    }
  }
}
