import { InternalAdapterAPI } from './InternalAdapterAPI';
import { HTML5InternalAdapter } from './HTML5InternalAdapter';
import { AnalyticsStateMachineOptions } from '../../types/AnalyticsStateMachineOptions';
import { QualityLevelInfo } from '../../types/QualityLevelInfo';
import { Player } from '../../enums/Player';
import { MIMETypes } from '../../enums/MIMETypes';

export class HlsInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  constructor(private hls: Hls, opts?: AnalyticsStateMachineOptions) {
    super(null, opts);
    this.resetMedia();
    this.registerHlsEvents();
  }

  public getPlayerName = () => Player.HLSJS;
  public getPlayerVersion = () => (this.hls as any).constructor.version;

  public getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    if (!this.hls.levels) {
      return null;
    }
    const currentLevelObj = this.hls.levels[this.hls.currentLevel];
    if (!currentLevelObj) {
      return null;
    }

    const bitrate = currentLevelObj.bitrate;
    const width = currentLevelObj.width;
    const height = currentLevelObj.height;

    return {
      bitrate,
      width,
      height,
    };
  }

  /**
   * @override
   */
  public isLive = () => {
    const hls = this.hls;
    if (hls.currentLevel < 0) {
      return false;
    }
    const currentLevelObj = hls.levels[hls.currentLevel];
    if (!currentLevelObj) {
      return false;
    }
    return currentLevelObj.details.live;
  }

  /**
   * @override
   */
  public getMIMEType() {
    return MIMETypes.HLS;
  }

  /**
   * @override
   */
  public getStreamURL() {
    // @ts-ignore
    return this.hls.url;
  }

  public registerHlsEvents() {
    const hls = this.hls as any;

    hls.on(hls.constructor.Events.MEDIA_ATTACHING, this.onMediaAttaching.bind(this));
    hls.on(hls.constructor.Events.MEDIA_DETACHING, this.onMediaDetaching.bind(this));
    hls.on(hls.constructor.Events.MANIFEST_LOADING, this.onManifestLoading.bind(this));

    // media is already attached, event has been triggered before
    // or we are in the event handler of this event itself.
    // we can not know how the stacktrace to this constructor will look like.
    // therefore we will guard from this case in
    // the onMediaAttaching method (avoid running it twice)
    if (hls.media) {
      this.onMediaAttaching();
      this.onManifestLoading();
    }
  }

  public onMediaAttaching() {
    // in case we are called again (when we are triggering this ourselves
    // but from the event handler of MEDIA_ATTACHING) we should not run again.
    if (this.mediaElement) {
      return;
    }

    this.mediaElement = this.hls.media;

    this.registerMediaElement();
    this.onMaybeReady();
  }

  public onMediaDetaching() {
    this.unregisterMediaElement();
  }

  public onManifestLoading() {
    this.onMaybeReady();
  }
}
