import { ProgressiveSourceConfig } from '../types/ProgressiveSourceConfig';
import { ProgressiveSourceInfo } from '../types/SourceInfo';

export const getSourceInfoFromBitmovinSourceConfig = (
  progressive: ProgressiveSourceConfig,
  player: any,
): ProgressiveSourceInfo => {
  if (!progressive) {
    return {
      progBitrate: undefined,
      progUrl: undefined,
    };
  }

  if (typeof progressive === 'string') {
    return {
      progBitrate: 0,
      progUrl: progressive,
    };
  }

  if (Array.isArray(progressive)) {
    const playbackVideoData = player.getPlaybackVideoData();
    let progressiveArrayIndex = +playbackVideoData.id;
    if (isNaN(progressiveArrayIndex)) {
      progressiveArrayIndex = 0;
    }

    return {
      progBitrate: progressive[progressiveArrayIndex].bitrate || 0,
      progUrl: progressive[progressiveArrayIndex].url,
    };
  }

  if (typeof progressive === 'object') {
    return {
      progBitrate: progressive.bitrate || 0,
      progUrl: progressive.url,
    };
  }

  return {
    progBitrate: undefined,
    progUrl: undefined,
  };
};
