import { endsWith } from '../utils/Utils';

const MP4 = 'video/mp4';
const WEBM = 'video/webm';
const HLS = 'application/x-mpegURL';
const DASH = 'application/dash+xml';

// tslint:disable-next-line:variable-name
export const MIMETypes = {
  MP4,
  WEBM,
  HLS,
  DASH,
};

export function getMIMETypeFromFileExtension(path: string): string | undefined {
  path = path.toLowerCase();

  if (endsWith(path, '.m3u8')) {
    return HLS;
  }
  if (endsWith(path, '.mp4') || endsWith(path, '.m4v') || endsWith(path, '.m4a')) {
    return MP4;
  }
  if (endsWith(path, '.webm')) {
    return WEBM;
  }
  if (endsWith(path, '.mpd')) {
    return DASH;
  }
}
