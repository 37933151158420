import { AnalyticsStateMachine } from '../../types/AnalyticsStateMachine';
import { StateMachineCallbacks } from '../../types/StateMachineCallbacks';
import { getCurrentTimestamp } from '../../utils/Utils';
import { AnalyticsStateMachineOptions } from '../../types/AnalyticsStateMachineOptions';
import { logger } from '../../utils/Logger';
import { AnalyticsConfig } from '../../types/AnalyticsConfig';
import { DownloadSpeedInfo } from '../../types/DownloadSpeedInfo';
import { SegmentInfo } from '../../types/SegmentInfo';
import { EventDispatcher } from '../../core/EventDispatcher';

export abstract class InternalAdapter {

  // tslint:disable:no-unused-expression
  public stateMachineCallbacks: StateMachineCallbacks = {
    setup: (time: number, state: string, event: string) => void {},
    startup: (time: number, state: string) => void {},
    updateSample: () => void {},
    playing: (time: number, state: string, event: string) => void {},
    playingAndBye: (time: number, state: string, event: string) => void {},
    heartbeat: (time: number, state: any, event: string) => void {},
    qualitychange: (time: number, state: string) => void {},
    qualitychange_pause: (time: number, state: string) => void {},
    qualitychange_rebuffering: (time: number, state: string) => void {},
    videoChange: (event: string) => void {},
    audioChange: (event: any) => void {},
    audiotrack_changing: () => void {},
    pause: (time: number, state: string, event: string) => void {},
    paused_seeking: (time: number, state: string, event: string) => void {},
    end_play_seeking: (time: number, state: string, event: string) => void {},
    rebuffering: (time: number, state: string, event: string) => void {},
    error: (event: any) => void {},
    end: (time: number, state: string, event: string) => void {},
    ad: (time: number, state: string, event: string) => void {},
    mute: () => void {},
    unMute: () => void {},
    subtitle_changing: () => void {},
    setVideoTimeEndFromEvent: (event: any) => void {},
    setVideoTimeStartFromEvent: (event: any) => void {},
    source_changing: (time: number, state: string, event: any) => void {},
    ready: (time: number, state: string, event: any) => void {},
    startCasting: (time: number, event: any) => void {},
    manualSourceChange: (event: {config: AnalyticsConfig}) => void {},
    initialSourceChange: (event: {config: AnalyticsConfig}) => void {},
    muting_ready: (time: number, state: string, event: any) => void {},
  };
  // tslint:enable:no-unused-expression
  protected stateMachine!: AnalyticsStateMachine;

  protected opts: AnalyticsStateMachineOptions;
  protected _onLicenseKeyReceived: EventDispatcher<{licenseKey: string}> = new EventDispatcher();
  protected _onLicenseCallFailed: EventDispatcher<{}> = new EventDispatcher();

  get onLicenseKeyReceived() {
    return this._onLicenseKeyReceived;
  }

  get onLicenseCallFailed() {
    return this._onLicenseCallFailed;
  }

  constructor(opts?: AnalyticsStateMachineOptions) {
    if (!opts) {
      opts = {
        starttime: undefined,
      };
    }
    if (!opts.starttime) {
      opts.starttime = getCurrentTimestamp();
    }

    this.opts = opts;
  }

  public eventCallback = (eventType: string, eventObject: any) => {
    eventObject = eventObject || {};
    if (!this.stateMachine) {
      logger.log('Bitmovin Analytics: StateMachine isn\'t ready yet');
    } else {
      this.stateMachine.callEvent(eventType, eventObject, getCurrentTimestamp());
    }
  }

  public getCommonPlaybackInfo() {
    return {
      screenHeight: screen.height,
      screenWidth: screen.width,
    };
  }

  public clearValues(): void {
    // noop by default
  }

  public clearSegments(): void {
    // noop by default
  }

  get downloadSpeedInfo(): DownloadSpeedInfo {
    return {
      segmentsDownloadCount: 0,
      segmentsDownloadSize: 0,
      segmentsDownloadTime: 0,
      avgDownloadSpeed: 0,
      minDownloadSpeed: 0,
      maxDownloadSpeed: 0,
      avgTimeToFirstByte: 0,
    };
  }

  get segments(): SegmentInfo[] {
    return [];
  }
}
