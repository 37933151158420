import { Adapter } from './Adapter';
import { AdapterAPI } from '../api/AdapterAPI';
import { AnalyticsStateMachineOptions } from '../types/AnalyticsStateMachineOptions';
import { AnalyticsConfig } from '../types/AnalyticsConfig';
import { VideojsInternalAdapter } from './internal/VideojsInternalAdapter';
import { Analytics } from '../core/Analytics';

export class VideojsAdapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();
    this.internalAdapter = new VideojsInternalAdapter(player as videojs.default.Player, opts);
    this.analytics = new Analytics(config, player, this.internalAdapter);
  }
}
