import { AdapterAPI } from '../api/AdapterAPI';
import { AnalyticsStateMachineOptions } from '../types/AnalyticsStateMachineOptions';
import { AnalyticsConfig } from '../types/AnalyticsConfig';
import { Analytics } from '../core/Analytics';
import { Adapter } from './Adapter';
import { ShakaInternalAdapter } from './internal/ShakaInternalAdapter';

export class ShakaAdapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();
    this.internalAdapter = new ShakaInternalAdapter(player, opts);
    this.analytics = new Analytics(config, player, this.internalAdapter);
  }
}
