export interface Subscribable<TArgs> {
    subscribe(callback: EventHandler<TArgs>): () => void;
    unsubscribe(callback: EventHandler<TArgs>);
}

type EventHandler<TArgs> = (args: TArgs) => void;

export class EventDispatcher<TArgs> implements Subscribable<TArgs> {
    private callbacks: Array<EventHandler<TArgs>> = [];

    public subscribe(callback: EventHandler<TArgs>): () => void {
        this.callbacks.push(callback);

        return () => this.unsubscribe(callback);
    }

    public dispatch(args: TArgs) {
        const callbacks = this.callbacks.slice(0);
        callbacks.forEach((callback) => {
          callback(args);
        });
    }

    public unsubscribe(callback: EventHandler<TArgs>) {
        const index = this.callbacks.indexOf(callback);
        if (index > -1) {
            this.callbacks.splice(index, 1);
        }
    }

    public get subscriberCount() {
        return this.callbacks.length;
    }
}
