import { Backend } from './Backend';
import { Sample } from '../types/Sample';
import { AdSample } from '../types/AdSample';
import { AdAnalyticsSample } from '../types/AdAnalyticsSample';
import { AdBreakSample } from '../types/AdBreakSample';

export class NoOpBackend implements Backend {
  public sendRequest(sample: Sample) {}
  public sendUnloadRequest(sample: Sample) {}
  public sendRequestSynchronous(sample: Sample) {}
  public sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {}
}
