import { post } from './Http';
import { Sample } from '../types/Sample';
import { AdSample } from '../types/AdSample';
import { joinUrls } from './Utils';
import { noOp } from '../utils/Utils';

export class AnalyticsCall {
  private analyticsServerUrl: string;
  private adAnalyticsServerUrl: string;

  constructor(baseUrl: string) {
    this.analyticsServerUrl =  joinUrls(baseUrl, 'analytics');
    this.adAnalyticsServerUrl = joinUrls(baseUrl, 'analytics/a');
  }

  public sendRequest(sample: Sample, callback: () => void) {
    post(this.analyticsServerUrl, sample, callback);
  }

  public sendUnloadRequest(sample: Sample) {
    this.trySendBeacon((data: Sample) => this.sendRequestSynchronous(data, noOp), this.getAnalyticsServerUrl(), sample);
  }

  public trySendBeacon(fallbackFunction: (data: any) => void, url: string, data: any) {
    if (typeof navigator.sendBeacon === 'undefined') {
      fallbackFunction(data);
    } else {
      const success = navigator.sendBeacon(url, JSON.stringify(data));
      if (!success) {
        fallbackFunction(data); 
     }
    }
  }

  public sendRequestSynchronous(sample: Sample, callback: () => void) {
    post(this.analyticsServerUrl, sample, callback, false);
  }

  public sendAdRequest(sample: AdSample) {
    this.trySendBeacon((data: AdSample) => post(this.getAdAnalyticsServerUrl(), data, noOp), this.getAdAnalyticsServerUrl(), sample);
  }

  public getAnalyticsServerUrl() {
    return this.analyticsServerUrl;
  }

  public getAdAnalyticsServerUrl() {
    return this.adAnalyticsServerUrl;
  }
}
