import { AnalyticsConfig } from '../types/AnalyticsConfig';
import { LicenseCheckingBackend } from './LicenseCheckingBackend';
import { DebuggingBackend } from './DebuggingBackend';
import { Backend } from './Backend';
import { LicensingRequest } from '../types/LicensingRequest';
import { SequenceNumberBackend } from './SequenceNumberBackend';
import { ANALYTICS_BACKEND_BASE_URL } from '../utils/Settings';
import { LicenseCall } from '../utils/LicenseCall';
import { DisableBackend } from './DisableBackend';
import { DeferredLicenseLoadingAdapterAPI } from '../adapters/internal/DeferredLicenseLoadingAdapterAPI';

export class BackendFactory {
  private backend?: DisableBackend;

  public createBackend(
    analyticsConfig: AnalyticsConfig,
    info: LicensingRequest,
    adapter: DeferredLicenseLoadingAdapterAPI
  ): Backend {
    const inner = this.createInnerBackend(analyticsConfig, info, adapter);
    const backend = analyticsConfig.debug
      ? new DebuggingBackend(analyticsConfig, inner)
      : inner;
    return backend;
  }

  protected createInnerBackend(
    analyticsConfig: AnalyticsConfig,
    info: LicensingRequest,
    adapter: DeferredLicenseLoadingAdapterAPI
  ): Backend {
    if (this.backend === undefined) {
      const backendUrl =
        (analyticsConfig.config && analyticsConfig.config.backendUrl) ||
        ANALYTICS_BACKEND_BASE_URL;
      const innerBackend = new LicenseCheckingBackend(
        info,
        LicenseCall,
        backendUrl,
        adapter
      );
      this.backend = new DisableBackend(innerBackend);
    }

    this.backend.enabled =
      analyticsConfig.config === undefined ||
      analyticsConfig.config.enabled !== false;
    return new SequenceNumberBackend(this.backend);
  }
}
