import * as Utils from '../utils/Utils';
import { AnalyticsConfig } from '../types/AnalyticsConfig';

const BITMOVIN_ANALYTICS_UUID_COOKIE_NAME = 'bitmovin_analytics_uuid';

export class SessionPersistenceHandler {

  private _cookiesEnabled: boolean;
  private _userId: string;

  get cookiesEnabled() {
    return this._cookiesEnabled;
  }

  get userId() {
    return this._userId;
  }

  constructor(config: AnalyticsConfig) {
    const collectorConfig = config.config;
    this._cookiesEnabled = (collectorConfig == null || collectorConfig.cookiesEnabled !== false);
    this._userId = this.setupUserId();
  }

  private createAndSetNewUserId() {
    const generatedId = Utils.generateUUID();
    this.writeCookie(generatedId);
    return generatedId;
  }

  private writeCookie(userId: string) {
    if (this.cookiesEnabled !== true) {
      return;
    }
    document.cookie = `${BITMOVIN_ANALYTICS_UUID_COOKIE_NAME}=${userId}`;
  }

  private setupUserId() {
    let userId = Utils.getCookie(BITMOVIN_ANALYTICS_UUID_COOKIE_NAME);
    if (Utils.isNullOrEmptyString(userId)) {
      userId = this.createAndSetNewUserId();
    }
    return userId;
  }
}
