import { Backend } from './Backend';
import { LicensingResponse, LicensingRequest, LicensingResult, LicenseCallFunc } from '../types/LicensingRequest';
import { QueueBackend } from './QueueBackend';
import { RemoteBackend } from './RemoteBackend';
import { logger } from '../utils/Logger';
import { NoOpBackend } from './NoOpBackend';
import { Sample } from '../types/Sample';
import { AdSample } from '../types/AdSample';
import { AdBreakSample } from '../types/AdBreakSample';
import { AdAnalyticsSample } from '../types/AdAnalyticsSample';
import { ANALYTICS_LICENSECALL_TIMEOUT } from '../utils/Settings';
import { DeferredLicenseLoadingAdapterAPI } from '../adapters/internal/DeferredLicenseLoadingAdapterAPI';

export class LicenseCheckingBackend implements Backend {
  public promise?: Promise<LicensingResponse>;
  private licenseLazyLoadingTimeoutHandle?: number;
  private backend: Backend;
  private info: LicensingRequest;

  constructor(
    info: LicensingRequest,
    private licenseCall: LicenseCallFunc,
    private backendBaseUrl: string,
    private adapter: DeferredLicenseLoadingAdapterAPI,
  ) {
    this.info = { ...info};
    this.backend = new QueueBackend();
    this.licenseCall = licenseCall;

    if (info.key !== undefined && info.key !== '') {
      this.promise = this.performLicenseCheck();
    } else if (adapter.supportsDeferredLicenseLoading === true) {
      adapter.onLicenseKeyReceived.subscribe(this.licenseKeyReceived);
      adapter.onLicenseCallFailed.subscribe(this.licenseCallFailed);
      this.licenseLazyLoadingTimeoutHandle = window.setTimeout(
        this.licenseLazyLoadingTimeout,
        ANALYTICS_LICENSECALL_TIMEOUT
      );
    } else {
      this.backend = new NoOpBackend();
    }
  }

  public performLicenseCheck(): Promise<LicensingResponse> {
    const { key, domain, version } = this.info;
    if (!key || key === '') {
      const error: LicensingResponse = {
        status: LicensingResult.Denied,
        message: 'No license key provided',
      };
      this.backend = new NoOpBackend();
      return Promise.resolve(error);
    }

    return this.licenseCall(key, domain, version, this.backendBaseUrl)
      .then((result) => {
        if (result.status === LicensingResult.Granted) {
          const remoteBackend = new RemoteBackend(true, this.backendBaseUrl, key);
          (this.backend as QueueBackend).flushTo(remoteBackend);
          this.backend = remoteBackend;
        } else {
          throw new Error(result.message);
        }
        return result;
      })
      .catch((err: Error) => {
        logger.errorMessageToUser('License Check for Bitmovin Analytics failed because of ', err);
        this.backend = new NoOpBackend();
        return {
          status: LicensingResult.Denied,
          message: err.message
        };
      });
  }

  public sendRequest(sample: Sample) {
    this.backend.sendRequest(sample);
  }
  public sendUnloadRequest(sample: Sample) {
    this.backend.sendUnloadRequest(sample);
  }
  public sendRequestSynchronous(sample: Sample) {
    this.backend.sendRequestSynchronous(sample);
  }
  public sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {
    this.backend.sendAdRequest(sample);
  }

  private licenseKeyReceived = (eventArgs: { licenseKey: string }) => {
    clearTimeout(this.licenseLazyLoadingTimeoutHandle);
    this.unsubscribeFromAdapter();
    this.info.key = eventArgs.licenseKey;
    this.promise = this.performLicenseCheck();
  }

  private licenseCallFailed = () => {
    clearTimeout(this.licenseLazyLoadingTimeoutHandle);
    this.unsubscribeFromAdapter();
    this.backend = new NoOpBackend();
  }

  private licenseLazyLoadingTimeout = () => {
    this.unsubscribeFromAdapter();
    this.backend = new NoOpBackend();
  }

  private unsubscribeFromAdapter() {
    this.adapter.onLicenseKeyReceived.unsubscribe(this.licenseKeyReceived);
    this.adapter.onLicenseCallFailed.unsubscribe(this.licenseCallFailed);
  }
}
