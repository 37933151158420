import { Backend } from './Backend';
import { AnalyticsConfig } from '../types/AnalyticsConfig';
import { Sample } from '../types/Sample';
import { AdSample } from '../types/AdSample';
import { AnalyticsDebugConfig } from '../types/AnalyticsDebugConfig';
import { logger } from '../utils/Logger';
import { AdBreakSample } from '../types/AdBreakSample';
import { AdAnalyticsSample } from '../types/AdAnalyticsSample';
import { LicensingRequest, LicensingResponse } from '../types/LicensingRequest';

export class DebuggingBackend implements Backend {
  private underlying: Backend;
  private debugFields?: string[];

  constructor(config: AnalyticsConfig, underlying: Backend) {
    this.underlying = underlying;
    if (config.debug && (config.debug as AnalyticsDebugConfig).fields) {
      this.debugFields = (config.debug as AnalyticsDebugConfig).fields;
    }
  }

  public sendRequest(sample: Sample) {
    this.printFields(sample);
    this.underlying.sendRequest(sample);
  }

  public sendUnloadRequest(sample: Sample) {
    this.printFields(sample);
    this.underlying.sendUnloadRequest(sample);
  }

  public sendRequestSynchronous(sample: Sample) {
    this.printFields(sample);
    this.underlying.sendRequestSynchronous(sample);
  }

  public sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {
    this.underlying.sendAdRequest(sample);
  }

  private printFields(sample: Sample) {
    if (this.debugFields && this.debugFields.length) {
      const fields = {};
      this.debugFields.forEach((f) => fields[f] = sample[f]);
      logger.logTable([fields]);
    }
  }
}
