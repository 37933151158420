import { AdModuleAPI, AdCallbacks } from './AdModuleAPI';
import { PlayerAPI, UserInteractionEvent } from 'bitmovin-player';

export class Bitmovin8AdModule implements AdModuleAPI {
  public adCallbacks = new AdCallbacks();

  constructor(private player: PlayerAPI) {
    this.register();
  }

  public isLinearAdActive = () => {
    if (this.player.ads) {
      return (this.player.ads as any).isLinearAdActive() || false;
    }
    return false;
  }
  public getContainer = () => this.player.getContainer();
  public getAdModuleInfo = () => {
    if (this.player.ads) {
      return (this.player.ads as any).getModuleInfo();
    }
    return { name: undefined, version: undefined };
  }
  public currentTime = () => this.player.getCurrentTime();

  private register() {
    this.player.on(this.player.exports.PlayerEvent.Play, (e: UserInteractionEvent) => {
        if (e.issuer === 'advertising-api') {
          this.adCallbacks.onPlay(e.issuer);
        }
    });
    this.player.on(this.player.exports.PlayerEvent.Paused, (e: UserInteractionEvent) => {
        if (e.issuer === 'advertising-api') {
          this.adCallbacks.onPause(e.issuer);
        }
    });
    this.player.on(this.player.exports.PlayerEvent.AdStarted, (event: any) => {
      this.adCallbacks.onAdStarted(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdFinished, (event: any) => {
      this.adCallbacks.onAdFinished(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdBreakStarted, (event: any) => {
        this.adCallbacks.onAdBreakStarted(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdBreakFinished, (event: any) => {
        this.adCallbacks.onAdBreakFinished(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdClicked, (event: any) => {
      this.adCallbacks.onAdClicked(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdQuartile, (event: any) => {
      this.adCallbacks.onAdQuartile(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdSkipped, (event: any) => {
      this.adCallbacks.onAdSkipped(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdError, (event: any) => {
      this.adCallbacks.onAdError(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdManifestLoaded, (event: any) => {
        this.adCallbacks.onAdManifestLoaded(event);
    });
    window.onunload = window.onbeforeunload = () => {
      this.adCallbacks.onBeforeUnload();
    };
  }
}
